// import i18n from '@/i18n/index'

export default {
  tableDataItem: [{
    prop: 'id',
    label: '',
    Object: 'value',
    width: '80'
  }, {
    prop: 'shop',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'name',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'must_buy_item',
    label: '',
    Object: 'value',
    width: '180'
  }, {
    prop: 'optional_item',
    label: '',
    Object: 'value',
    width: '180'
  }, {
    prop: 'discount_type',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'validity',
    label: '',
    Object: 'value',
    width: '180'
  }, {
    prop: 'online',
    label: '',
    Object: 'value',
    width: '80'
  }]
}
