// import * as hostWithdrawRequestsApi from '@/services/host-withdraw-requests.js'
import * as bundleApi from '@/services/bundles.js'
import loading from '@/utils/loading.json'
import jsonData from './jsonData.js'
import allButton from '@/components/allButton'
import searchForm from '@/components/searchForm'
import createTable from '@/components/createTable'
import dialogEffect from '@/components/dialogEffect'
import { mixin } from '@/mixins/mixin'

export default {
  mixins: [mixin],
  components: {
    searchForm,
    createTable,
    allButton,
    dialogEffect
  },
  data () {
    return {
      tableDataItem: jsonData.tableDataItem,
      tabActivityIndex: 0
    }
  },
  mounted () {
    const _this = this
    _this.queryData(bundleApi.getBundleSetup)
  },
  activated () {
    if (this.isActive) this.queryData(bundleApi.getBundleSetup)
    this.isActive = true
  },
  methods: {
    setGlobalLoading (show) {
      const _this = this
      show ? _this.$store.state.app.loading = this.$loading(loading) : this.$store.state.app.loading.close()
    },
    // 查询
    search (data) {
      if (data) this.searchData = data
      const { page, per_page } = this.pageData
      Object.assign(this.searchData, { page, per_page })
      this.loading = true
      bundleApi.getBundleList(this.searchData).then(res => {
        if (res.data) {
          this.tableData = []
          this.$nextTick(() => {
            this.tableData = res.data.objects
            this.tabData = res.data.scopes
            Object.assign(this.pageData, res.data.pagination)
          })
        }
      }).finally(() => {
        this.loading = false
      })
    },
    // 点击操作按钮
    handleClick (type, data) {
      const _this = this
      if (type === 'create') {
        this.$router.push({
          name: 'addBundle'
        })
      } else if (type?.action === 'view') {
        _this.$router.push({
          name: 'bundleDetail',
          params: {
            id: data.id.value
          }
        })
      } else if (type?.action === 'edit') {
        _this.$router.push({
          name: 'bundleEdit',
          params: {
            id: data.id.value
          }
        })
      } else if (type?.action === 'destroy') {
        this.deleteClick(data, bundleApi.deleteBundle)
      }
    },
    forwardToPage (data, name) {
      if (name === 'shop') {
        this.$router.push({
          name: 'shopsManagerDetail',
          params: {
            id: data.id
          }
        })
      }
    },
    handleCreateTableSwitchChange (data) {
      const _this = this
      const { rowData, jsonItemData } = data
      if (jsonItemData.prop === 'online') {
        if (rowData[jsonItemData.prop].value) {
          _this.setGlobalLoading(true)
          bundleApi.postBundleItemsIdOnline(rowData.id.value).then(() => {
            _this.setGlobalLoading(false)
            _this.$message({
              type: 'success',
              message: _this.$t('message.operateSuccess')
            })
            _this.search()
          }).catch(() => {
            _this.setGlobalLoading(false)
            _this.search()
          })
        } else {
          _this.setGlobalLoading(true)
          bundleApi.postBundleItemsIdOffline(rowData.id.value).then(() => {
            _this.setGlobalLoading(false)
            _this.$message({
              type: 'success',
              message: _this.$t('message.operateSuccess')
            })
            _this.search()
          }).catch(() => {
            _this.setGlobalLoading(false)
            _this.search()
          })
        }
      }
    }
  }
}
